<template>
  <div class="row">
    <div class="col-12 bg-pink">
      <!-- CONTENT GOES HERE -->
      <card class="mb-2">
        <div class="row align-items-center">
          <div class="col-8 col-lg-11">
            <h5 class="h3">{{getTrans('messages.smartlink')}}</h5>
          </div>
          <div class="col-4 col-lg-1">
            <el-button type="primary" size="small" @click="tools"
              >{{getTrans('messages.back')}}</el-button
            >
          </div>
        </div>
        <div class="container-fluid">
          <div class="row block align-items-center">
            <div class="col-12">
              <span class="title">{{getTrans('messages.smartlink_address')}}</span>
              <span>{{getTrans('messages.smartlink_description')}}</span
              >
              <div class="row justify-content-center">
                <div class="col-12 smart-link" id="SmartLinkCopy">
                  <!-- eslint-disable -->
                  <p class="text-center link border border-light">
                    https://diva.services/smartlink/?{{ smartLinkParameters }}
                  </p>
                  <!-- eslint-enable -->
                </div>
                <div class="col-12 text-center">
                  <el-button class="block" type="primary" @click="copyLink"
                    >{{getTrans('messages.copy')}}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-12">
              <span class="title">{{getTrans('messages.smartlink_setting')}}</span>
              <ToolOptionsSelector
                @updated="updated"
                :showModules="[
                  'camsites',
                  'profiles',
                  'tracking',
                  'fallurl',
                  'gender'
                ]"
              ></ToolOptionsSelector>
            </div>
          </div>
        </div>
      </card>
      <!-- END OF CONTENT -->
    </div>
  </div>
</template>
<script>
// Components
import { mapGetters } from "vuex";
import CopyToClipboard from "@/mixins/CopyToClipboard";
import ToolOptionsSelector from "./components/ToolOptionsSelector";
import {
  ElButton,
  ElRadio,
  ElButtonGroup,
  ElRadioButton,
  ElCollapse,
  ElCollapseItem
} from "element-plus";
export default {
  name: "smart-link",
  components: {
    ToolOptionsSelector,
    [ElButton.name]: ElButton,
    [ElRadio.name]: ElRadio,
    [ElButtonGroup.name]: ElButtonGroup,
    [ElRadioButton.name]: ElRadioButton,
    [ElCollapse.name]: ElCollapse,
    [ElCollapseItem.name]: ElCollapseItem,
  },
  mixins: [CopyToClipboard],
  data() {
    return {
      datavalues: { profiles: [] },
      loading: false
    };
  },
  methods: {
    tools() {
      this.$router.push({ name: "Tools" });
    },
    copyLink() {
      this.copyToClipboard("SmartLinkCopy");
      this.$toast.success("Smartlink copied!");
    },
    updated(options) {
      this.datavalues = options;
      this.$forceUpdate();
    },
    camsitesParameters(camsites) {
      var selected = camsites
        .map(c => {
          return c.id;
        })
        .join(",");
      if (selected == "") {
        selected = "";
      }
      return "c=" + selected + "&";
    },
    profilesParameters(profiles) {
      return (
        "p" +
        profiles
          .map(p => {
            return p.id;
          })
          .join(",") +
        "&"
      );
    },
    trackingParameters(tracking) {
      var param = "";
      for (var name in tracking) {
        param += name + "=" + tracking[name] + "&";
      }
      return param;
    }
  },
  computed: {
    ...mapGetters("Camsite", ["getCamsites"]),
    ...mapGetters("Profile", {getProfiles: 'getList'}),

    filteredProfiles() {
      var prof = this.getProfiles
        ? this.getProfiles.filter(p => {
            var correctCamsite =
              this.selectedCamsite && p.performer
                ? p.performer.data.platform_id == this.selectedCamsite
                : true;
            var correctName =
              this.currentSearch && p.performer
                ? p.performer.data.name
                    .toLowerCase()
                    .indexOf(this.currentSearch.toLowerCase()) >= 0
                : true;
            return correctCamsite && correctName;
          })
        : [];
      return prof;
    },
    smartLinkParameters() {
      var link = "";
      if (Array.isArray(this.datavalues.camsites)) {
        link += this.camsitesParameters(this.datavalues.camsites);
      }
      if (this.datavalues.profiles && this.datavalues.profiles.length) {
        link += this.profilesParameters(this.datavalues.profiles);
      }
      if (this.datavalues.tracking) {
        link += this.trackingParameters(this.datavalues.tracking);
      }
      if (this.datavalues.gender) {
        link += "g=" + this.datavalues.gender + "&";
      }
      if (this.datavalues.fallurl && this.datavalues.fallurl.url) {
        link += "fallback=" + this.datavalues.fallurl.url;
      }
      return link;
    }
  }
};
</script>
<style></style>
